var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "w-4",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 0 14 14" },
    },
    [
      _c("title", [_vm._v("Client")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.08,9.07a.77.77,0,0,0,.19.17s.06,0,\n    .06.08a.89.89,0,0,0,.26.57.19.19,0,0,1,0,\n    .07,1.71,1.71,0,0,1,0,.32,2.13,2.13,0,0,0,\n    .71.16,2.4,2.4,0,0,0,.78-.17v-.08a.33.33,0,\n    0,1,0-.1c0-.23,0-.15.09-.25a1.2,1.2,0,0,0,\n    .18-.48.12.12,0,0,1,.06-.13.45.45,0,0,0,.13-.66c-.05-.06,0-.06,0-.17a1.42,\n    1.42,0,0,0,0-.91,1,1,0,0,0-.91-.78,1.24,1.24,0,0,0-.87.15A.57.57,\n    0,0,1,12.6,7a.71.71,0,0,0-.56.67,2.13,2.13,0,0,0,\n    .11.79.15.15,0,0,1,0,.13A.44.44,0,0,0,12.08,9.07Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M14.89,11a3.22,3.22,0,0,1-.74-.31,2.11,2.11,0,0,\n    1-.8.21,1.77,1.77,0,0,1-.77-.2,6.3,6.3,0,0,1,.58,\n    2.57v.15a.66.66,0,0,1-.1.37h2.66c.15,0,.28-.1.27-.26C16,\n    13.07,16,11.37,14.89,11Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.39,9.07a.77.77,0,0,0,.19.17.12.12,0,0,\n    1,.06.08.89.89,0,0,0,.26.57.19.19,0,0,1,0,.07,\n    1.71,1.71,0,0,1,0,.32,2.07,2.07,0,0,0,.71.16,\n    2.4,2.4,0,0,0,.78-.17s0,0,0-.08v-.1c0-.23,\n    0-.15.1-.25a1.2,1.2,0,0,0,.18-.48.12.12,0,0,\n    1,.06-.13.46.46,0,0,0,.13-.66c0-.06,0-.06,0-.17a1.42,\n    1.42,0,0,0,0-.91A1,1,0,0,0,5,6.71a1.24,1.24,0,0,\n    0-.87.15A.65.65,0,0,1,3.91,7a.71.71,0,0,0-.56.67,\n    1.94,1.94,0,0,0,.11.79.15.15,0,0,1,0,.13A.44.44,0,0,0,3.39,9.07Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.11,11a3.22,3.22,0,0,0,.74-.31,2.11,2.11,0,0,0,\n    .8.21,1.77,1.77,0,0,0,.77-.2,6.3,6.3,0,0,0-.58,2.57v.15a.66.66,0\n    ,0,0,.1.37H2.27c-.15,0-.28-.1-.27-.26C2,13.07,2.05,11.37,3.11,11Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M7.25,7.45a.86.86,0,0,0,.26.24s.07,0,.07.11a1.18,1.18,0,0,0,\n    .36.77.19.19,0,0,1,0,.1A3.08,3.08,0,0,1,8,9.1a2.57,2.57,0,0,0,1,\n    .22A3,3,0,0,0,10,9.09.45.45,0,0,0,10,9V8.85c0-.32,0-.21.13-.35a1.4,\n    1.4,0,0,0,.24-.65c0-.1,0-.11.09-.17a.63.63,0,0,0,.18-.91c-.06-.08,\n    0-.08,0-.23a1.88,1.88,0,0,0,0-1.24A1.36,1.36,0,0,0,9.44,4.23a1.76,\n    1.76,0,0,0-1.18.2A.75.75,0,0,1,8,4.58a1,1,0,0,0-.76.92,2.83,2.83,\n    0,0,0,.15,1.08.19.19,0,0,1,0,.18A.57.57,0,0,0,7.25,7.45Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M5.72,13.78h6.5c.22,0,.39-.14.37-.35,0-.64-.06-3-1.52-3.45a5.24,\n    5.24,0,0,1-1-.44,2.63,2.63,0,0,1-1.1.3,2.57,2.57,0,0,1-1.09-.3,\n    5.33,5.33,0,0,1-1,.44c-1.45.49-1.48,2.81-1.52,3.45C5.33,13.64,\n    5.51,13.77,5.72,13.78Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }